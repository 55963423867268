<template>
    <BT-Blade-Item
        bladeName="courier"
        :bladesData="bladesData"
        :canEdit="false"
        :canSave="false"
        cardWidth="1100"
        :loadingMsg="loadingMsg"
        navigation="couriers"
        :refreshToggle="refreshToggle"
        title="Courier">
        <template v-slot="{ data, item }">
            <v-container v-if="data.isNew">
                <v-row>
                    <v-col cols="6">
                        <BT-Field-String
                            v-model="item.companyName"
                            label="Courier Name"
                            isEditing
                            :rules="requiredRules()" />
                            
                        <BT-Field-String
                            v-model="item.email"
                            label="Email Address"
                            isEditing
                            :rules="requiredRules()" />

                        <BT-Field-String
                            v-model="item.phoneNumber"
                            label="Phone Number"
                            isEditing />

                    </v-col>
                    <v-col cols="6">
                        <BT-Field-String
                            label="Address Line One"
                            v-model="item.addressLineOne"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Street Number"
                            v-model="item.streetNumber"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Street Name"
                            v-model="item.streetName"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Suburb"
                            v-model="item.suburb"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="State"
                            v-model="item.state"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Postcode"
                            v-model="item.postcode"
                            :isEditing="item.companyName != null && item.email != null" />
                    </v-col>
                </v-row>
                <v-divider />
                <v-row>
                    <v-col cols="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-btn class="primary" :disabled="item.suburb == null && item.state == null && item.postcode == null" @click="validate(item)">
                                    Find Address Coordinates
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>

                        <BT-Field-String
                            label="Latitude"
                            v-model="item.lat"
                            isEditing />

                        <BT-Field-String
                            label="Longitude"
                            v-model="item.lng"
                            isEditing />
                    </v-col>
                    <v-col cols="6">
                        <GmapMap
                            v-if="item.lat != null && item.lng != null"
                            ref="locMap"
                            :center="centerPosition"
                            :zoom="7"
                            style="width: 100%; height: 250px;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">
                            <gmap-marker v-if="position != null" :position="position" />
                        </GmapMap>
                    </v-col>
                </v-row>
                <v-divider />
                <v-btn v-if="item.lat != null && item.lng != null" class="primary" @click="create(item)">
                    Create
                </v-btn>
                <BT-Snack v-model="msg" />
            </v-container>
            <v-container v-else>
                <BT-Entity
                    v-if="item.seller.isManagedByAnotherAccount"
                    ignoreID
                    navigation="managed-company-details"
                    :proxyID="item.sellerID"
                    single>
                    <template v-slot="{ item, data, save }">
                        <v-row>
                            <v-col cols="12" sm="6" class="text-center">
                                <BT-Image-Edit
                                    v-if="data.isEditing"
                                    :canEdit="data.isEditing"
                                    :height="150"
                                    :id="item.id"
                                    justify-center align-center
                                    navigation="customer-image"
                                    placeholder="mdi-account-box"
                                    :src="companyLogoURL(item.id) + '?' + cacheData"
                                    @uploaded="cacheData = new Date().getTime()"
                                    :width="150" />
                                <v-img
                                    v-else
                                    height="150"
                                    :src="companyLogoURL(item.id)"
                                    width="150">
                                    <template v-slot:placeholder>
                                        <v-icon size="150" color="primary">mdi-account-box</v-icon>
                                    </template>
                                </v-img>

                            </v-col>
                            <v-col cols="12" sm="6">
                                <BT-Field-String
                                    v-model="item.companyName"
                                    label="Company Name"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />
                                    
                                <BT-Field-String
                                    v-model="item.primaryEmail"
                                    label="Primary Email Address"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />

                                <BT-Field-String
                                    v-model="item.phoneNumber"
                                    label="Phone Number"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-String
                                    v-model="item.website"
                                    label="Website"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-Select
                                    label="Default Timezone"
                                    v-model="item.defaultTimeZone"
                                    :items="timeZones"
                                    :isEditing="data.isEditing"
                                    :rules="requiredRules()" />

                            </v-col>
                        </v-row>
                        <v-slide-y-transition hide-on-leave>
                            <v-row v-if="data.isChanged" class="mt-0">
                                <v-btn v-if="data.isChanged" @click="save" block class="primary mt-5">
                                    <v-icon left>mdi-content-save</v-icon>Save
                                </v-btn>
                            </v-row>
                        </v-slide-y-transition>
                    </template>
                </BT-Entity>
                <v-row v-else>
                    <v-col cols="12" sm="6" class="text-center">
                        <v-img
                            class="mx-auto"
                            height="150"
                            :src="companyLogoURL(item.sellerID)"
                            width="150">
                            <template v-slot:placeholder>
                                <v-icon size="150" color="primary">mdi-account-box</v-icon>
                            </template>
                        </v-img>
                    </v-col>
                    <v-col cols="6">
                        <BT-Field-String
                            v-model="item.seller.companyName"
                            label="Company Name"
                            :rules="requiredRules()" />
                    </v-col>
                </v-row>
                <v-divider class="my-1" />

                <v-row v-if="item != null">
                    <v-col cols="1">
                        <v-list width="56">
                            <BT-Menu
                                v-if="item.seller.isManagedByAnotherAccount" 
                                icon="mdi-map-marker-multiple"
                                :item="item"
                                title="Locations">
                                <template>
                                    <BT-Blade-Items
                                        addBladeName="location"
                                        canAdd
                                        hideBackButton
                                        hideHeader
                                        navigation="locations"
                                        :proxyID="item.sellerID"
                                        showList>
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item | toFamiliarLocationLine }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Items>
                                </template>
                            </BT-Menu>
                            
                            <BT-Menu
                                icon="mdi-arrow-decision"
                                :isTrue="item => item.clientCanRequestFulfillment == true && !item.isSuspended"
                                :itemValue="item.id"
                                navigation="couriers-fulfilling"
                                title="Fulfillment Services">
                                <template v-slot="fulSettings">
                                    <!-- <BT-Entity
                                        inline
                                        :itemValue="item.id"
                                        navigation="couriers-fulfilling"
                                        single>
                                        <template v-slot="fulSettings"> -->
                                            <v-btn v-if="fulSettings.item.isSuspended" class="error">
                                                Suspended
                                            </v-btn>

                                            <BT-Field-Switch
                                                v-model="fulSettings.item.clientCanRequestFulfillment"
                                                label="Fulfillment Service" />
                                            
                                        <!-- </template>
                                    </BT-Entity> -->
                                </template>
                            </BT-Menu>

                        </v-list>
                    </v-col>
                    <v-col cols="11">
                        <v-row class="mt-2">
                            
                            <v-spacer />

                            <BT-Sidebar-External-Links
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.id"
                                label="Integrate Fulfillment Services"
                                navigation="couriers-fulfilling"
                                small
                                syncNavigation="fulfiller-syncing" />

                            <BT-Sidebar-Live-Links
                                v-if="item.seller.isManagedByAnotherAccount"
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.sellerID"
                                label="Go Live"
                                rightIcon="mdi-account-multiple-check"
                                small
                                sidebarLabel="Links" />
                            <div v-else class="mx-2 px-2 subtitle-1">
                                <v-icon left>mdi-account</v-icon>Live
                            </div>
                        </v-row>
                        
                        <v-row>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    addBladeName="courier-order"
                                    canSearchLocal
                                    :headers="[
                                        { text: 'CO#', value: 'courierOrderNumber', subtitle: 4, prefix: 'CO# ' },
                                        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' }, 
                                        { text: 'Departure From', value: 'departureLocation', display: true, subtitle: 3, prefix: 'From: ' },
                                        { text: 'Destination', value: 'destinationLocation', display: true, subtitle: 2, prefix: 'To: ' },
                                        { text: 'Status', value: 'id', display: true, subtitle: 5, prefix: 'Status: ' }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="5"
                                    navigation="courier-orders"
                                    :params="{ sellerID: item.sellerID }"
                                    showTable
                                    title="Courier Orders">
                                    <template v-slot:id="{ item }">
                                        <span v-if="item.fulfilledOn != null">Fulfilled</span>
                                        <span v-else-if="item.isBilled">Billed</span>
                                        <span v-else-if="item.isPlanned">Planned</span>
                                        <span v-else-if="item.isApproved === true">Approved</span>
                                        <span v-else-if="item.isApproved === false">Rejected</span>
                                        <span v-else-if="item.isApproved == null">Unresponded</span>
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5" />
                        <v-row>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    v-if="item != null && item.seller.isManagedByAnotherAccount"
                                    addBladeName="courier-movement"
                                    canSearchLocal
                                    :headers="[
                                        { text: 'Name', value: 'bundleName' },
                                        { text: 'Due On', value: 'dueArrivalOn', display: true },
                                        { text: 'From', value: 'departureLocation', textFilter: 'toFamiliarLocationLine' },
                                        { text: 'To', value: 'destinationLocation', display: true, textFilter: 'toFamiliarLocationLine' },
                                        { text: 'Journey', value: 'journey', display: true },
                                        { text: 'Status', value: 'status', display: true }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="3"
                                    navigation="courier-movements"
                                    showTable
                                    title="Movements" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- <v-expansion-panels v-model="panelV">
                    <BT-Blade-Expansion-Item
                        v-if="item.seller.isManagedByAnotherAccount"
                        navigation="managed-company-details"
                        ignoreID
                        :itemID="null"
                        :proxyID="item.sellerID"
                        title="Company Details">
                        <template v-slot="{ item, data }">
                            <BT-Image-Edit
                                v-if="data.isEditing"
                                label="Edit Logo"
                                justify-center align-center
                                navigation="customer-image"
                                placeholder="mdi-account-box"
                                class="mx-auto"
                                :id="item.id"
                                :proxyID="item.id"
                                :src="companyLogoURL(item.id)"
                                @uploaded="cacheData = new Date().getTime()" />
                            
                            <v-img
                                v-else
                                :src="companyLogoURL(item.id)"
                                height="100"
                                width="100">
                                <template v-slot:placeholder>
                                    <v-icon size="100" color="primary">mdi-cube-outline</v-icon>
                                </template>
                            </v-img>
                            
                            <BT-Field-String
                                label="Company Name"
                                v-model="item.companyName"
                                :isEditing="data.isEditing"
                                :rules="requiredRules()" />

                            <BT-Field-String
                                label="Primary Email Address"
                                v-model="item.primaryEmail"
                                :isEditing="data.isEditing"
                                :rules="requiredRules()" />

                            <BT-Field-String
                                label="Website"
                                v-model="item.website"
                                :isEditing="data.isEditing" />

                            <BT-Field-String
                                label="Phone Number"
                                v-model="item.phoneNumber"
                                :isEditing="data.isEditing" />

                            <BT-Field-Select
                                label="Default Timezone"
                                v-model="item.defaultTimeZone"
                                :items="timeZones"
                                :isEditing="data.isEditing"
                                :rules="requiredRules()" />

                        </template>
                    </BT-Blade-Expansion-Item>
                    <BT-Blade-Expansion-Item
                        v-else
                        :canEdit="false"
                        :canRefresh="false"
                        :canSave="false"
                        :item="item.seller"
                        title="Company Details">
                        <template v-slot="{ item }">
                            <v-img
                                :src="companyLogoURL(item.id)"
                                height="100"
                                width="100">
                                <template v-slot:placeholder>
                                    <v-icon size="100" color="primary">mdi-account</v-icon>
                                </template>
                            </v-img>
                            
                            <BT-Field-String
                                label="Company Name"
                                v-model="item.companyName"
                                :rules="requiredRules()" />

                        </template>
                    </BT-Blade-Expansion-Item>
                    <BT-Blade-Expansion-Item
                        v-if="item != null"
                        :itemID="item.id"
                        navigation="couriers"
                        title="Courier Settings">
                        <template>
                            
                        </template>
                    </BT-Blade-Expansion-Item>
                    <BT-Blade-Expansion-Items
                        v-if="item != null && item.seller.isManagedByAnotherAccount"
                        addBladeName="location"
                        canAdd
                        canDelete
                        :headers="[
                            { text: 'Name', value: 'locationName' },
                            { text: 'Line One', value: 'addressLineOne' },
                            { text: 'Number', value: 'streetNumber' }, 
                            { text: 'Street', value: 'streetName' },
                            { text: 'Suburb', value: 'suburb' },
                            { text: 'State', value: 'state' },
                            { text: 'Postcode', value: 'postcode' }]"
                        navigation="locations"
                        :proxyID="item.sellerID"
                        title="Locations" />
                    <BT-Blade-Expansion-Items
                        v-if="item != null && item.seller.isManagedByAnotherAccount"
                        addBladeName="courier-movement"
                        :canAdd="false"
                        canDelete
                        :canExportCSV="false"
                        :headers="[
                            { text: 'Name', value: 'bundleName' },
                            { text: 'Due On', value: 'dueArrivalOn', display: true },
                            { text: 'From', value: 'departureLocation', textFilter: 'toFamiliarLocationLine' },
                            { text: 'To', value: 'destinationLocation', display: true, textFilter: 'toFamiliarLocationLine' },
                            { text: 'Journey', value: 'journey', display: true },
                            { text: 'Status', value: 'status', display: true }]"
                        navigation="courier-movements"
                        :proxyID="item.sellerID"
                        title="Movements" />
                </v-expansion-panels> -->
            </v-container>
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Courier-Blade',
    components: {
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
    },
    props: {
        bladesData: null
    },
    data: function() {
        return {
            cacheData: new Date().getTime(),
            geocoder: null,
            loadingMsg: null,
            msg: null,
            panelV: null,
            position: null,
            refreshToggle: false,
        }
    },
    computed: {
        centerPosition() {
            return this.position != null ? this.position : { lat: -38, lng: 144 };
        },
        timeZones() {
            return moment.tz.names();
        }
    },
    methods: {
       repositionMarker(location) {
            if (location.lat != null && location.lng != null) {
                this.position = {
                    lat: location.lat,
                    lng: location.lng
                }
            }
            else {
                this.position = null;
            }
        },
        async create(item) {
            this.loadingMsg = `Creating ${item.companyName}`;

             var data = {
                subscriptionCode: 'COURFREE',
                companies: [item]
            };

            try {
                await this.$BlitzIt.api.post('new-couriers', data, null, '/PostCouriers');

                this.msg = 'Done';
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async validate(item) {
            try {
                this.loadingMsg = 'Validating Address';
            
                var add = getLocationLine(item, true);
                
                await this.$gmapApiPromiseLazy();
                var google = getGoogleMapsAPI();

                if (this.geocoder == null) {
                    //await googleMapsApiInitializer({ key: process.env.VUE_APP_GOOGLE_KEY }, false);
                    this.geocoder = new google.maps.Geocoder();
                }

                var results = await this.geocoder.geocode({ address: add });

                if (results.results.length > 1) {
                    this.msg = 'Multiple Options Found';
                }
                else if (results.results.length == 1) {
                    item.lat = results.results[0].geometry.location.lat();
                    item.lng = results.results[0].geometry.location.lng();
                    this.repositionMarker(item);
                }
                else {
                    this.msg = 'Address Not Found';
                }
            }
            catch (err) {
                this.msg = 'Failed';
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>